import axios from 'axios';
import * as config from 'src/config';
import { FETCH_STATUS } from 'src/constants';
import {
  MANIFEST_BACKLOGS_SYNC_STATUS,
  TManfiestBacklogs,
  TManifestBacklogsEvents,
  TManifestBacklogsFilters,
  TManifestFilters,
  TManifestSyncBacklogsEventsPayload,
} from 'src/reducers/backlogs';
import { SortableSessionsDataProps } from 'src/views/Backlogs/SortableSessions/SortableSessions';
import { v4 as uuidv4 } from 'uuid';

export const SET_MANIFEST_SESSION_OVERVIEW = 'manifest/SET_MANIFEST_SESSION_OVERVIEW';
export const SET_MANIFEST_SERVICE_APPLETS = 'manifest/SET_MANIFEST_SERVICE_APPLETS';
export const SET_MANIFEST_FILTERS = 'manifest/SET_MANIFEST_FILTERS';
export const SET_MANIFEST_BACKLOGS = 'manifest/SET_MANIFEST_BACKLOGS';
export const SET_MANIFEST_BACKLOGS_FILTERS = 'manifest/SET_MANIFEST_BACKLOGS_FILTERS';
export const SET_MANIFEST_BACKLOGS_EVENTS = 'manifest/SET_MANIFEST_BACKLOGS_EVENTS';
export const CLEAR_MANIFEST_SERVICE_APPLETS = 'manifest/CLEAR_MANIFEST_SERVICE_APPLETS';
export const CLEAR_MANIFEST = 'manifest/CLEAR_MANIFEST';

export const setManifestSessionOverview = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SESSION_OVERVIEW, data: value });
  };
};

export const setManifestServiceApplets = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_SERVICE_APPLETS, data: value });
  };
};

export const setManifestFilters = (value: Partial<TManifestFilters>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_FILTERS, data: value });
  };
};

export const setManifestBacklogs = (value: Partial<TManfiestBacklogs>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS, data: value });
  };
};

export const setManifestBacklogsFilters = (value: Partial<TManifestBacklogsFilters>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_FILTERS, data: value });
  };
};

export const setManifestBacklogsEvents = (value: Partial<TManifestBacklogsEvents>) => {
  return (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, data: value });
  };
};

export const syncManifestBacklogsEvents = (payload: TManifestSyncBacklogsEventsPayload) => {
  return async (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.PENDING });

    return axios
      .post(`${config.endpoints.base_url}v1/events/batch`, payload)
      .then((res) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.RESOLVED });
        return res;
      })
      .catch((err) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.ERROR });
        throw new Error(err);
      });
  };
};

export const submitManifestBacklogsEvents = ({
  data,
  calcStatus,
}: {
  data: SortableSessionsDataProps;
  calcStatus: MANIFEST_BACKLOGS_SYNC_STATUS;
}) => {
  const payload: TManifestSyncBacklogsEventsPayload = {
    sessions: [
      {
        session_id: data.id,
        app_code: 'sos',
        start_timestamp: data.sos[0]?.sos_in?.timestampUTC ?? data.timestampUTC ?? '',
        end_timestamp: data.sos[data.sos.length - 1]?.sos_out?.timestampUTC ?? data.timestampUTC ?? '',
        compliance_list: [],
        site_tz: data.siteTz,
        network_id: 0,
        network_name: 'viana',
        site_name: data.siteName,
        site_id: data.siteID,
        doc_id: data.id,
        critical_events: [],
        calc_status: calcStatus,
        status: calcStatus,
        validations: data.sos?.flatMap((x) => {
          const genNonSessionId = uuidv4();
          return [
            {
              non_session_id: x.sos_in?.id,
              event_code: x.sos_in?.eventCode ?? '',
              event_location_id: x.sos_in?.locationID ?? 0,
              event_location_name: x.sos_in?.locationName ?? '',
              event_site_tz: data.siteTz,
              event_site_timestamp_utc: x.sos_in?.timestampUTC ?? '',
              event_network_id: 0,
              event_network_name: 'viana',
              event_asset: '', //need pa mahibaw.an asa kuhaon
              event_timestamp: x.sos_in?.timestampUTC ?? '',
              event_start_timestamp: x.sos_in?.timestampUTC ?? '',
              event_end_timestamp: x.sos_out?.timestampUTC ?? '',
              original_event_code: x.sos_in?.eventCode ?? '',
              original_session_id: x.sos_in?.originalSessionId ?? '',
              session_id: data.id,
              calc_non_session_id: genNonSessionId,
              status: MANIFEST_BACKLOGS_SYNC_STATUS.FOR_HMI,
              date_validated: new Date().toISOString(),
            },
            {
              non_session_id: x.sos_out?.id,
              event_code: x.sos_out?.eventCode ?? '',
              event_location_id: x.sos_out?.locationID ?? 0,
              event_location_name: x.sos_out?.locationName ?? '',
              event_site_tz: data.siteTz,
              event_site_timestamp_utc: x.sos_out?.timestampUTC ?? '',
              event_network_id: 0,
              event_network_name: 'viana',
              event_asset: '',
              event_timestamp: x.sos_out?.timestampUTC ?? '',
              event_start_timestamp: x.sos_in?.timestampUTC ?? '',
              event_end_timestamp: x.sos_out?.timestampUTC ?? '',
              original_event_code: x.sos_out?.eventCode ?? '',
              original_session_id: x.sos_out?.originalSessionId ?? '',
              session_id: data.id,
              calc_non_session_id: genNonSessionId,
              status: MANIFEST_BACKLOGS_SYNC_STATUS.FOR_HMI,
              date_validated: new Date().toISOString(),
            },
            {
              non_session_id: genNonSessionId,
              event_code: x.eventCode ?? '',
              event_location_id: x.sos_in?.locationID ?? 0,
              event_location_name: x.sos_in?.locationName ?? '',
              event_site_tz: data.siteTz,
              event_site_timestamp_utc: x.sos_in?.timestampUTC ?? '',
              event_network_id: 0,
              event_network_name: 'viana',
              event_asset: '', //need pa mahibaw.an asa kuhaon
              event_timestamp: x.sos_in?.timestampUTC ?? '',
              event_start_timestamp: x.sos_in?.timestampUTC ?? '',
              event_end_timestamp: x.sos_out?.timestampUTC ?? '',
              original_event_code: x.eventCode ?? '',
              original_session_id: x.sos_in?.originalSessionId ?? '',
              session_id: data.id,
              calc_non_session_id: null,
              status: MANIFEST_BACKLOGS_SYNC_STATUS.FOR_HMI,
              date_validated: new Date().toISOString(),
            },
          ];
        }),
      },
    ],
  };
  return async (dispatch) => {
    dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.PENDING });

    return axios
      .post(`${config.endpoints.base_url}v1/events/batch`, payload)
      .then((res) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.RESOLVED });
        return res;
      })
      .catch((err) => {
        dispatch({ type: SET_MANIFEST_BACKLOGS_EVENTS, fetchStatus: FETCH_STATUS.ERROR });
        throw new Error(err);
      });
  };
};

export const clearManifestServiceApplets = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST_SERVICE_APPLETS });
  };
};

export const clearManifest = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MANIFEST });
  };
};
